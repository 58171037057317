import {Component, createRef, Fragment} from "react";
import {sprintf} from "sprintf-js";

import "../../styles/viewer/ZoomableImage.css";
import * as ReactDOM from "react-dom";

class ZoomableImage extends Component
{
  static props = {
    src: '',
  }
  
  state = {
    showImage: false,
    zoomLevel: 1,
    minZoomLevel: 1
  }
  
  constructor(props) {
    super(props);
    this.imageNode = createRef();
    this.pannStart = undefined;
    
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.onImageOver = this.onImageOver.bind(this);
    this.onWheel = this.onWheel.bind(this);
  }
  
  componentDidMount() {
    if(this.imageNode.current) {
      this.imageNode.current.addEventListener('wheel', this.onWheel, { passive: false });
      this.imageNode.current.addEventListener('mousemove', this.onImageOver);
      this.imageNode.current.addEventListener('mousedown', this.onMouseDown, { passive: false });
      this.imageNode.current.addEventListener('mouseup', this.onMouseUp, { passive: false });
      this.imageNode.current.addEventListener('mouseout', this.onMouseOut, { passive: false });
      
    }
  }
  
  componentWillUnmount() {
    if(this.imageNode.current)
    {
      this.imageNode.current.removeEventListener('wheel', this.onWheel);
      this.imageNode.current.removeEventListener('mousemove', this.onImageOver);
      this.imageNode.current.removeEventListener('mousedown', this.onMouseDown);
      this.imageNode.current.removeEventListener('mouseup', this.onMouseUp);
      this.imageNode.current.removeEventListener('mouseout', this.onMouseOut);
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.src===undefined || prevProps.src != this.props.src)
    {
      this.setState({
        showImage: this.props.src && !(this.props.src.length==0)
      });
    }
  }
  
  onWheel(e)
  {
    e.preventDefault();
    if(!this.state.showImage) return;

    const image = this.imageNode.current;
    const imageRect = image.parentElement.getBoundingClientRect();
    
    const oldScale = this.state.zoomLevel;
    const delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
    const scale = (delta > 0) ? this.state.zoomLevel + 0.2 : this.state.zoomLevel - 0.2;
    const scaleChange = 0.2;
    
    this.setState({
      zoomLevel: Math.clamp(scale, 1, 5)
    }, () => {
      var deltaX = ((e.clientX - imageRect.x) * scaleChange);
      var deltaY = ((e.clientY - imageRect.y) * scaleChange);
      image.parentNode.scrollLeft += deltaX;
      image.parentNode.scrollTop += deltaY;
    });
  }
  
  onMouseDown(e) {
    e.preventDefault();
    
    if (!this.state.showImage) return;
    
    if (e.button === 1)
    {
      this.pannStart = { x: e.clientX, y: e.clientY };
    } else if(e.button === 0)
    {
      const p = this.getImagePosition(e.clientX, e.clientY);
      console.log(`clientX: ${e.clientX} -> ${p.x}`);
    }
  }
  
  onMouseOut(e) {
    this.pannStart = undefined;
  }
  
  onMouseUp(e) {
    e.preventDefault();
    
    if (!this.state.showImage) return;
  
    if (e.button === 1)
    {
      this.pannStart = undefined;
    }
  }
  
  onImageOver(e)
  {
    if(!this.state.showImage) return;

    if(this.pannStart !== undefined) {
      const x = this.pannStart.x - e.clientX;
      const y = this.pannStart.y - e.clientY;
      this.imageNode.current.parentElement.scrollLeft += x;
      this.imageNode.current.parentElement.scrollTop += y;
      this.pannStart = { x: e.clientX, y: e.clientY };
    } else {
      if(this.props.onImageOver)
      {
        const p = this.getImagePosition(e.clientX, e.clientY);
        this.props.onImageOver(p.x, p.y, this.props.alt);
      }
    }
  }
  
  getImagePosition(x, y) {
    if(!this.state.showImage) return;
    const image = this.imageNode.current;
    const imagePos = image.getBoundingClientRect();
    const hRatio = image.naturalWidth / image.width;
    const vRatio = image.naturalHeight / image.height;
    const px = Math.clamp(Math.floor((x - Math.trunc(imagePos.x)) * hRatio), 0, image.naturalWidth - 1);
    const py = Math.clamp(Math.floor((y - Math.trunc(imagePos.y)) * vRatio), 0, image.naturalHeight - 1);
    return { x: px, y: py };
  }
  
  render() {
    const imgStyle = {
      width: `${this.state.zoomLevel * 100}%`
    };
    
    let _src = `data:image/bmp;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=`;
    let  _alt = 'singlepixel';
    
    if(this.state.showImage)
    {
      _src = this.props.src;
      _alt = this.props.alt;
    }
    
    return (
      <Fragment>
        {this.state.showImage &&
          <span className={"zoom-value"}>Zoom: {sprintf("%d",this.state.zoomLevel * 100)}%</span>
        }
        <div className={"zoomable-image" + (this.state.showImage ? " zoomactive" : "")}>
          <img alt={_alt}
               ref={this.imageNode}
               style={imgStyle}
               src={_src}/>
        </div>
      </Fragment>
    )
  }
}

export default ZoomableImage;